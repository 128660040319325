import React from "react";
import { render } from "react-dom";

import { useState, useEffect } from "react";

import { start, Clock, PlaybackState, MembraneSynth } from "tone";
import { useRef, useCallback } from "react";

import { Sample } from "./Sample";

import { fill } from "lodash";

function resize<T>(array: T[], size: number, filler: T) {
  if (array.length === size) {
    return array;
  } else if (array.length > size) {
    return array.slice(0, size);
  } else {
    return array.concat(fill(Array(size - array.length), filler));
  }
}

function change<T>(array: T[], index: number, value: T) {
  return [...array.slice(0, index), value, ...array.slice(index + 1)];
}

function App() {
  const [sequence, updateSequence] = useState(() => fill(Array(16), false));

  const { current: clock } = useRef(new Clock(() => {}, 4));
  const { current: synth } = useRef(new MembraneSynth().toDestination());

  useEffect(() => {
    clock.callback = (time, ticks) => {
      if (ticks !== undefined) {
        if (ticks >= sequence.length) {
          clock.setTicksAtTime(0, time);
          ticks = 0;
        }

        if (sequence[ticks]) {
          synth.triggerAttackRelease("C2", "8n", time);
        }
      }
    };
  }, [clock, synth, sequence]);

  const [playState, setPlayState] = useState<PlaybackState>("stopped");

  useEffect(() => {
    function handleStart() {
      setPlayState("started");
    }

    function handleStop() {
      setPlayState("stopped");
    }

    clock.on("start", handleStart);
    clock.on("stop", handleStop);

    return () => {
      clock.off("start", handleStart);
      clock.off("stop", handleStop);
    };
  }, [clock]);

  return (
    <>
      <svg width="500" height="500">
        <circle
          cx={250}
          cy={250}
          r={225}
          fill="none"
          stroke="#000"
          strokeWidth={5}
        />
        <Playhead clock={clock} length={sequence.length} />
        {sequence.map((step, i) => (
          <Tick
            key={i}
            position={i / sequence.length}
            active={step}
            onClick={() => {
              updateSequence((seq) => change(seq, i, !step));
            }}
          />
        ))}
      </svg>
      <input
        type="range"
        min={2}
        max={16}
        step={1}
        value={sequence.length}
        onChange={({ target: { value } }) => {
          updateSequence((seq) => resize(seq, parseInt(value), false));
        }}
      />
      <button
        onClick={() => {
          start();
          if (playState === "started") {
            clock.stop();
          } else {
            clock.start();
          }
        }}
      >
        {playState === "started" ? "Stop" : "Start"}
      </button>
      <Sample />
    </>
  );
}

type PlayheadProps = {
  clock: Clock;
  length: number;
};

function Playhead({ clock, length }: PlayheadProps) {
  const [playState, setPlayState] = useState<PlaybackState>("stopped");
  const [ticks, setTicks] = useState(0);

  useEffect(() => {
    let frame: number;

    console.log("set up effect");

    function update() {
      setTicks(clock.getTicksAtTime());
      frame = requestAnimationFrame(update);
    }

    function handleStart() {
      console.log("started...");
      frame = requestAnimationFrame(update);
      setPlayState("started");
    }

    function handleStop() {
      cancelAnimationFrame(frame);
      setPlayState("stopped");
    }

    clock.on("start", handleStart);
    clock.on("stop", handleStop);

    return () => {
      cancelAnimationFrame(frame);
      clock.off("start", handleStart);
      clock.off("stop", handleStop);
    };
  }, [clock]);

  const position = ticks / length;

  return (
    <g
      transform={`translate(${250 + 225 * Math.sin(position * 2 * Math.PI)}, ${
        250 - 225 * Math.cos(position * 2 * Math.PI)
      }) rotate(${position * 360})`}
    >
      <rect x={-2.5} y={-20} width={5} height={40} />
    </g>
  );
}

type TickProps = {
  position: number;
  active: boolean;
  onClick: () => any;
};

function Tick({ position, active, onClick }: TickProps) {
  return (
    <g
      transform={`translate(${250 + 225 * Math.sin(position * 2 * Math.PI)}, ${
        250 - 225 * Math.cos(position * 2 * Math.PI)
      }) rotate(${position * 360})`}
    >
      <rect x={-2.5} y={-20} width={3} height={30} />
      {active ? <circle r={10} fill="#ff00ff" /> : null}
      <circle r={20} opacity={0} onClick={onClick} />
    </g>
  );
}

window.addEventListener("load", () => {
  render(<App />, document.getElementById("root"));
});
