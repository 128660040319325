import React, { useEffect } from "react";
import { Player } from "tone";

export function Sample() {
  useEffect(() => {
    const player = new Player(
      "https://raw.githubusercontent.com/tidalcycles/Dirt-Samples/master/808bd/BD5000.WAV"
    ).toDestination();
    player.autostart = true;
  }, []);
  return null;
}
